import React, { useState } from "react";

const AuthContext = React.createContext({
	token: "",
	isLoggedIn: false,
	analyticsOptin: false,
	role: 1,
	login: (token) => {},
	logout: () => {},
	openResponsiveMenuHandler: () => {},
});

export const AuthContextProvider = (props) => {
	const initialToken = localStorage.getItem("token");
	const initialRole = localStorage.getItem("role");
	const initialAnalytics = localStorage.getItem("analyticsConsent");

	const [token, setToken] = useState(initialToken);
	const [role, setRole] = useState(initialRole);
	const [consentAsked, setConsentAsked] = useState(false);
	const [responsiveMenuOpen, setResponsiveMenuOpen] = useState(false);

	const userIsLoggedIn = !!token;

	const openResponsiveMenuHandler = () => {
		setResponsiveMenuOpen(!responsiveMenuOpen);
	};

	const closeMenu = () => {
		setResponsiveMenuOpen(false);
	};

	const isConsentSelected = () => {
		if (initialAnalytics === null) {
			return false;
		} else {
			return true;
		}
	};

	const acceptAnalytics = () => {
		localStorage.setItem("analyticsConsent", true);
		setConsentAsked(true);
	};

	const rejectAnalytics = () => {
		localStorage.setItem("analyticsConsent", false);
		setConsentAsked(true);
	};

	const logoutHandler = () => {
		setToken(null);
		localStorage.removeItem("token");
		setRole(1);
		localStorage.removeItem("role");
	};

	const loginHandler = (token) => {
		localStorage.setItem("token", token);
		localStorage.setItem("analyticsOptin", true);
		setToken(token);
		// Expiration of token, 86400000ms is one day
		setTimeout(logoutHandler, 86400000);
		localStorage.setItem("analyticsConsent", true);
	};

	const roleHandler = (roleId) => {
		if (roleId) {
			roleId = String(roleId);
			setRole(roleId);
			localStorage.setItem("role", roleId);
		}
	};

	const contextValue = {
		token: token,
		isLoggedIn: userIsLoggedIn,
		role: role,
		login: loginHandler,
		logout: logoutHandler,
		checkRole: roleHandler,
		acceptAnalytics: acceptAnalytics,
		rejectAnalytics: rejectAnalytics,
		isConsentSelected: isConsentSelected,
		openResponsiveMenuHandler: openResponsiveMenuHandler,
		responsiveMenuOpen: responsiveMenuOpen,
		closeMenu: closeMenu,
	};
	return (
		<AuthContext.Provider value={contextValue}>
			{props.children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
