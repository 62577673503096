import React, { useState } from "react";
import { IntlProvider } from "react-intl";

import English from "../lang/en.json";
import French from "../lang/fr.json";
import Polish from "../lang/pl.json";
import Greek from "../lang/gr.json";
import Spanish from "../lang/es.json";

export const Context = React.createContext();
let localeLang;
if (localStorage.getItem("localeLang")) {
	localeLang = localStorage.getItem("localeLang");
} else {
	localeLang = navigator.language.split("-")[0];
	localStorage.setItem("localeLang", localeLang);
}

let lang;
switch (localeLang) {
	case "en":
		lang = English;
		break;
	case "fr":
		lang = French;
		break;
	case "pl":
		lang = Polish;
		break;
	case "el":
		lang = Greek;
		break;
	case "es":
		lang = Spanish;
		break;
	default:
		lang = English;
}

export const Wrapper = (props) => {
	const [locale, setLocale] = useState(localeLang);
	const [messages, setMessages] = useState(lang);
	function selectLanguage(e) {
		const newLocale = e.target.value;
		setLocale(newLocale);
		localStorage.setItem("localeLang", newLocale);
		switch (newLocale) {
			case "en":
				setMessages(English);
				break;
			case "fr":
				setMessages(French);
				break;
			case "pl":
				setMessages(Polish);
				break;
			case "el":
				setMessages(Greek);
				break;
			case "es":
				setMessages(Spanish);
				break;
			default:
				setMessages(English);
		}
	}
	return (
		<Context.Provider value={{ locale, selectLanguage }}>
			<IntlProvider messages={messages} locale={locale}>
				{props.children}
			</IntlProvider>
		</Context.Provider>
	);
};
export default Wrapper;
