import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const usePageTracking = () => {
	const location = useLocation();

	useEffect(() => {
		if (localStorage.getItem("analyticsConsent") !== "false") {
			ReactGA.initialize("UA-213995930-1");
			ReactGA.set({ anonymizeIp: true });
			ReactGA.pageview(location.pathname + location.search);
		}
	});
};

export default usePageTracking;
