import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

const ScrollToTop = () => {
	const [isVisible, setIsVisible] = useState(false);

	// Top: 0 takes us all the way back to the top of the page
	// Behavior: smooth keeps it smooth!
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	useEffect(() => {
		// Button is displayed after scrolling for 300 pixels
		const toggleVisibility = () => {
			if (window.pageYOffset > 300) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener("scroll", toggleVisibility);

		return () => window.removeEventListener("scroll", toggleVisibility);
	}, []);

	//scroll-to-top classes: fixed, bottom:0, right:0
	return (
		<>
			<div
				style={{
					position: "fixed",
					bottom: 3,
					right: 3,
				}}>
				{isVisible && (
					<div
						onClick={scrollToTop}
						style={{
							position: "fixed",
							padding: "15px",
							backgroundColor: "#fbb142",
							borderRadius: "10px",
							margin: "10px",
							bottom: 3,
							right: 3,
						}}>
						<span>
							<FontAwesomeIcon icon={faArrowUp} />
						</span>
					</div>
				)}
			</div>
		</>
	);
};
export default ScrollToTop;
