import { Route, Switch, Redirect } from "react-router-dom";
import React, { useContext, Suspense } from "react";

import ScrollToTop from "./contexts/scrollToTop";

import AuthContext from "./contexts/auth-context";
import { Helmet } from "react-helmet";
import usePageTracking from "./scripts/usePageTracking";

import { CircularProgress, Box } from "@material-ui/core";

const Login = React.lazy(() => import("./pages/public/Login"));
const Reset = React.lazy(() => import("./pages/public/Reset"));
const Register = React.lazy(() => import("./pages/public/Register"));
const Dashboard = React.lazy(() => import("./pages/private/Dashboard"));
const Homepage = React.lazy(() => import("./pages/public/Homepage"));
const Data = React.lazy(() => import("./pages/public/Data"));
const TermsAndConditions = React.lazy(() =>
	import("./pages/public/TermsAndConditions")
);
const Explore = React.lazy(() => import("./pages/public/Explore"));
const Europeana = React.lazy(() => import("./pages/public/Europeana"));

const CollectionDetail = React.lazy(() =>
	import("./pages/private/CollectionDetail")
);
const DashboardMyCollections = React.lazy(() =>
	import("./pages/private/DashboardMyCollections")
);
const DashboardMyExhibitions = React.lazy(() =>
	import("./pages/private/DashboardMyExhibitions")
);
const Settings = React.lazy(() => import("./pages/private/Settings"));
const Welcome = React.lazy(() => import("./pages/public/Welcome"));
const CreateExhibition = React.lazy(() =>
	import("./pages/private/CreateExhibition")
);
const CreateCollection = React.lazy(() =>
	import("./pages/private/CreateCollection")
);
const EditCollection = React.lazy(() =>
	import("./pages/private/EditCollection")
);
const ExhibitionDetail = React.lazy(() =>
	import("./pages/private/ExhibitionDetail")
);
const ClassesList = React.lazy(() => import("./pages/private/ClassesList"));
const ForgotPwd = React.lazy(() => import("./pages/public/ForgotPwd"));

function App() {
	usePageTracking();
	const authCtx = useContext(AuthContext);

	return (
		<div className="App">
			<ScrollToTop />
			<Helmet>
				<title>VX Designers</title>
				<meta
					name="description"
					content="A free tool designed for classes"
				/>
			</Helmet>
			<Suspense
				fallback={
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						minHeight="100vh">
						<CircularProgress size={100} />
					</Box>
				}>
				<Switch>
					<Route
						path="/confirm/:confirmationCode"
						component={Welcome}
					/>
					<Route
						path="/terms-and-conditions"
						component={TermsAndConditions}
					/>
					<Route path="/data" component={Data} />
					<Route path="/europeana" component={Europeana} />

					<Route path="/explore" component={Explore} />

					<Route path="/dashboard">
						{authCtx.isLoggedIn && (
							<>
								<Dashboard />
							</>
						)}
						{!authCtx.isLoggedIn && <Redirect to="/login" />}
					</Route>

					<Route exact path="/collections">
						{authCtx.isLoggedIn && <DashboardMyCollections />}
						{!authCtx.isLoggedIn && <Redirect to="/login" />}
					</Route>

					<Route exact path="/exhibitions">
						{authCtx.isLoggedIn && <DashboardMyExhibitions />}
						{!authCtx.isLoggedIn && <Redirect to="/login" />}
					</Route>

					{authCtx.isLoggedIn && (
						<Route path="/exhibitions/create">
							<CreateExhibition />
						</Route>
					)}

					{authCtx.isLoggedIn && (
						<Route path="/collections/create">
							<CreateCollection />
						</Route>
					)}

					{authCtx.isLoggedIn && (
						<Route path="/classes">
							<ClassesList />
						</Route>
					)}

					{authCtx.isLoggedIn && (
						<Route path="/settings">
							<Settings />
						</Route>
					)}

					<Route path="/exhibitions/:exhibitionId">
						<ExhibitionDetail />
					</Route>

					<Route exact path="/collections/:collectionId">
						<CollectionDetail />
					</Route>

					<Route path="/collections/:collectionId/edit">
						<EditCollection />
					</Route>

					{!authCtx.isLoggedIn && (
						<Route exact path="/login">
							<Login title="Login" />
						</Route>
					)}

					{!authCtx.isLoggedIn && (
						<Route path="/register">
							<Register />
						</Route>
					)}

					<Route exact path="/">
						<Homepage />
					</Route>

					<Route exact path="/logout">
						<Homepage />
					</Route>

					<Route path="/login/reset/:pwdToken" component={Reset} />
					<Route exact path="/login/forgot-password">
						<ForgotPwd />
					</Route>

					<Route path="*">
						{/* Or page 404 */}
						<Redirect to="/" />
					</Route>
				</Switch>
			</Suspense>
		</div>
	);
}

export default App;
